import React from 'react';
import style from './VisitorStatistics.module.scss';
import PieChartStats from '../Charts/PieChart';
import { Empty } from 'antd';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

const VisitorStatistics = ({ data }) => {
  const {
    interacted_with_chatbot = 0,
    false_lead_interacted_with_chatbot = 0,
    percentage_of_users_interacted_with_chatbot
  } = data;

  const chartData = [
    { name: 'No. of visitors interacted w/Chatbot', value: interacted_with_chatbot },
    { name: 'No. of False lead interacted w/Chatbot', value: false_lead_interacted_with_chatbot },

  ];
  const isDataEmpty = chartData[0].value === 0 && chartData[1].value === 0;

  return (
    <div className={style.statistics}>
      <div className={style.statItem}>
      <div>
          <h3 className={style.chartHeader}>Visitor Statistics</h3>
        </div>
       
      </div>
      <div>
      {isDataEmpty ? (
          <div className={style.noData}>
            <Empty description={
      <span>
        No vistor statistics
      </span>
    } />
          </div>
        ) : (
      <PieChartStats data={chartData} COLORS={COLORS} label={`${parseFloat(percentage_of_users_interacted_with_chatbot).toFixed()} % `} labelTitle="Interacted" />
        )}
      </div>  
      <div className={style.statItem}>
       
        <div>
          <h1>{false_lead_interacted_with_chatbot}
            <span style={{ backgroundColor: COLORS[2], width: '20px', height: '26px', display: 'inline-block', marginLeft: '10px', borderRadius:'4px' }}></span>
          </h1>
          <h3 className={style.chartHeader}>No. of False lead interacted w/Chatbot</h3>
        </div>
        <div>
          <h1>{interacted_with_chatbot}
            <span style={{ backgroundColor: COLORS[0], width: '20px', height: '26px', display: 'inline-block', marginLeft: '10px',borderRadius:'4px' }}></span>
          </h1>
          <h3 className={style.chartHeader}>No. of visitors interacted w/Chatbot</h3>
        </div>
      </div>
    </div>
  );
};

export default VisitorStatistics;
